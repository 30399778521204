<template>
    <v-container fluid>
        <v-form @submit.prevent="saveParkRestaurantMenu">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn @click="setBack()" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-center">
                                {{ $t('restaurant_menu_editing_text')}}
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="menu_ru" rules="required"
                                                    v-slot="{ errors, valid }">


                                    <v-col class="px-0 mx-0" cols="12" sm="12" v-if="!showDataScannerRu(data_menu_ru)">
                                        <v-btn
                                            elevation="1"
                                            @click="addScannedRu"
                                        >
                                            {{ $t('menu_in_russian')}}
                                        </v-btn>
                                    </v-col>

                                    <v-col class="px-0 mx-0 py-0 my-0" cols="12" sm="12" v-if="showDataScannerRu(data_menu_ru)">
                                        <v-list subheader >
                                            <v-list-item class="px-0 mx-0"  @click="downloadFile(data_menu_ru)">
                                                <v-list-item-icon>
                                                    <v-img
                                                        max-width="90"
                                                        :alt="`${data_menu_ru.file_name}`"
                                                        :src="data_menu_ru.url"
                                                        max-height="60"
                                                    ></v-img>
                                                    <v-icon
                                                        x-large
                                                        v-if="!mimeTypeImage(data_menu_ru.file_type)">mdi-file-document-outline mdi-48px</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title v-text="data_menu_ru.file_name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="(data_menu_ru.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                                </v-list-item-content>

                                                <v-list-item-icon class="d-flex align-self-center mt-2">
                                                    <v-btn
                                                        icon
                                                        @click="clearScannedRu"
                                                    >
                                                        <v-icon> mdi-delete-outline</v-icon>
                                                    </v-btn>

                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-file-input v-model="menu_ru"
                                                  class="px-0 mx-0"
                                                  id="menu_ru_input"
                                                  hide-input
                                                  prepend-icon=""
                                                  accept=".pdf"
                                                  :disabled="loading"
                                                  :error="!valid"
                                                  @change="previewScannedRu"
                                    ></v-file-input>

                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="menu_en" rules="required"
                                                    v-slot="{ errors, valid }">


                                    <v-col class="px-0 mx-0" cols="12" sm="12" v-if="!showDataScannerEn(data_menu_en)">
                                        <v-btn
                                            elevation="1"
                                            @click="addScannedEn"
                                        >
                                            {{ $t('menu_in_english')}}
                                        </v-btn>
                                    </v-col>

                                    <v-col class="px-0 mx-0 py-0 my-0" cols="12" sm="12" v-if="showDataScannerEn(data_menu_en)">
                                        <v-list subheader >
                                            <v-list-item class="px-0 mx-0"  @click="downloadFile(data_menu_en)">
                                                <v-list-item-icon>
                                                    <v-img
                                                        max-width="90"
                                                        :alt="`${data_menu_en.file_name}`"
                                                        :src="data_menu_en.url"
                                                        max-height="60"
                                                    ></v-img>
                                                    <v-icon
                                                        x-large
                                                        v-if="!mimeTypeImage(data_menu_en.file_type)">mdi-file-document-outline mdi-48px</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title v-text="data_menu_en.file_name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="(data_menu_en.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                                </v-list-item-content>

                                                <v-list-item-icon class="d-flex align-self-center mt-2">
                                                    <v-btn
                                                        icon
                                                        @click="clearScannedEn"
                                                    >
                                                        <v-icon> mdi-delete-outline</v-icon>
                                                    </v-btn>

                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-file-input v-model="menu_en"
                                                  class="px-0 mx-0"
                                                  id="menu_en_input"
                                                  hide-input
                                                  prepend-icon=""
                                                  accept=".pdf"
                                                  :disabled="loading"
                                                  :error="!valid"
                                                  @change="previewScannedEn"
                                    ></v-file-input>

                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="menu_kk" rules="required"
                                                    v-slot="{ errors, valid }">


                                    <v-col class="px-0 mx-0" cols="12" sm="12" v-if="!showDataScannerKk(data_menu_kk)">
                                        <v-btn
                                            elevation="1"
                                            @click="addScannedKk"
                                        >
                                            {{ $t('menu_in_kazakh')}}
                                        </v-btn>
                                    </v-col>

                                    <v-col class="px-0 mx-0 py-0 my-0" cols="12" sm="12" v-if="showDataScannerKk(data_menu_kk)">
                                        <v-list subheader >
                                            <v-list-item class="px-0 mx-0"  @click="downloadFile(data_menu_kk)">
                                                <v-list-item-icon>
                                                    <v-img
                                                        max-width="90"
                                                        :alt="`${data_menu_kk.file_name}`"
                                                        :src="data_menu_kk.url"
                                                        max-height="60"
                                                    ></v-img>
                                                    <v-icon
                                                        x-large
                                                        v-if="!mimeTypeImage(data_menu_kk.file_type)">mdi-file-document-outline mdi-48px</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title v-text="data_menu_kk.file_name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="(data_menu_kk.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                                </v-list-item-content>

                                                <v-list-item-icon class="d-flex align-self-center mt-2">
                                                    <v-btn
                                                        icon
                                                        @click="clearScannedKk"
                                                    >
                                                        <v-icon> mdi-delete-outline</v-icon>
                                                    </v-btn>

                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-file-input v-model="menu_kk"
                                                  class="px-0 mx-0"
                                                  id="menu_kk_input"
                                                  hide-input
                                                  prepend-icon=""
                                                  accept=".pdf"
                                                  :disabled="loading"
                                                  :error="!valid"
                                                  @change="previewScannedKk"
                                    ></v-file-input>

                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-6">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";

    export default {
        name: 'ParkRestaurantMenu',
        components: {
            ValidationProvider,
            ValidationObserver
        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                menu_ru: null,
                new_menu_ru: 0,
                data_menu_ru: {},
                menu_en: null,
                new_menu_en: 0,
                data_menu_en: {},
                menu_kk: null,
                new_menu_kk: 0,
                data_menu_kk: {},










                progress: 0,
                loading: false,
                tab: 0,
                language: null,


            }
        },
        computed: {
            ...mapGetters(['listLanguages',  'lang']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.checkCreate()
            this.getParkRestaurantMenu();
        },
        methods: {
            setLanguage(val) {
                this.language = this.languages[this.tab]
                this.checkCreate()
            },
            checkCreate() {
                this.heading = this.$t('restaurant_menu_editing')
                this.getParkRestaurantMenu()
            },
            setBack(){
                window.history.back();
            },
            showDataScannerRu(item){
                if(item){
                    if(item.file_name){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                return false;
            },
            previewScannedRu() {
                let document = this.menu_ru;
                this.data_menu_ru = {}
                this.data_menu_ru.url = URL.createObjectURL(document)
                this.data_menu_ru.file_name = document.name
                this.data_menu_ru.blob =  document
                this.data_menu_ru.size = document.size
                this.data_menu_ru.file_type = document.type
                this.new_menu_ru = 1;
            },
            clearScannedRu(){
                this.menu_ru = null
                this.data_menu_ru  = {}
            },
            addScannedRu(){
                document.getElementById('menu_ru_input').click()
            },

            showDataScannerEn(item){
                if(item){
                    if(item.file_name){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                return false;
            },
            previewScannedEn() {
                let document = this.menu_en;
                this.data_menu_en = {}
                this.data_menu_en.url = URL.createObjectURL(document)
                this.data_menu_en.file_name = document.name
                this.data_menu_en.blob =  document
                this.data_menu_en.size = document.size
                this.data_menu_en.file_type = document.type
                this.new_menu_en = 1;
            },
            clearScannedEn(){
                this.menu_en = null
                this.data_menu_en  = {}
            },
            addScannedEn(){
                document.getElementById('menu_en_input').click()
            },

            showDataScannerKk(item){
                if(item){
                    if(item.file_name){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                return false;
            },
            previewScannedKk() {
                let document = this.menu_kk;
                this.data_menu_kk = {}
                this.data_menu_kk.url = URL.createObjectURL(document)
                this.data_menu_kk.file_name = document.name
                this.data_menu_kk.blob =  document
                this.data_menu_kk.size = document.size
                this.data_menu_kk.file_type = document.type
                this.new_menu_kk = 1;
            },
            clearScannedKk(){
                this.menu_kk = null
                this.data_menu_kk  = {}
            },
            addScannedKk(){
                document.getElementById('menu_kk_input').click()
            },
            async getParkRestaurantMenu() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/park_restaurant_menu`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.data_menu_ru = res.body.data.menu_ru
                        if(res.body.data.menu_ru_base64){
                            this.menu_ru  = new File([atob(res.body.data.menu_ru_base64)], res.body.data.menu_ru.file_name, {
                                type: "application/pdf",
                            })
                        }

                        this.data_menu_en = res.body.data.menu_en
                        if(res.body.data.menu_en_base64){
                            this.menu_en  = new File([atob(res.body.data.menu_en_base64)], res.body.data.menu_en.file_name, {
                                type: "application/pdf",
                            })
                        }

                        this.data_menu_kk = res.body.data.menu_kk
                        if(res.body.data.menu_kk_base64){
                            this.menu_kk  = new File([atob(res.body.data.menu_kk_base64)], res.body.data.menu_kk.file_name, {
                                type: "application/pdf",
                            })
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_restaurant_menu'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveParkRestaurantMenu() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }

                if (this.new_menu_ru) {
                    formData.append('new_menu_ru', 1)
                }
                if (this.data_menu_ru.size) {
                    formData.append('menu_ru_size', this.data_menu_ru.size)
                }
                if (this.data_menu_ru.file_name) {
                    formData.append('menu_ru_file_name', this.data_menu_ru.file_name)
                }
                if (this.data_menu_ru.file_type) {
                    formData.append('menu_ru_file_type', this.data_menu_ru.file_type)
                }
                if (this.menu_ru) {
                    formData.append('menu_ru', this.menu_ru)
                }

                if (this.new_menu_en) {
                    formData.append('new_menu_en', 1)
                }
                if (this.data_menu_en.size) {
                    formData.append('menu_en_size', this.data_menu_en.size)
                }
                if (this.data_menu_en.file_name) {
                    formData.append('menu_en_file_name', this.data_menu_en.file_name)
                }
                if (this.data_menu_en.file_type) {
                    formData.append('menu_en_file_type', this.data_menu_en.file_type)
                }
                if (this.menu_en) {
                    formData.append('menu_en', this.menu_en)
                }

                if (this.new_menu_kk) {
                    formData.append('new_menu_kk', 1)
                }
                if (this.data_menu_kk.size) {
                    formData.append('menu_kk_size', this.data_menu_kk.size)
                }
                if (this.data_menu_kk.file_name) {
                    formData.append('menu_kk_file_name', this.data_menu_kk.file_name)
                }
                if (this.data_menu_kk.file_type) {
                    formData.append('menu_kk_file_type', this.data_menu_kk.file_type)
                }

                if (this.menu_kk) {
                    formData.append('menu_kk', this.menu_kk)
                }

                    // Add
                    await this.$http
                        .put('admin/park_restaurant_menu', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('restaurant_menu_has_been_update'))

                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('restaurant_menu_has_not_been_update'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })

            },
            downloadFile(data) {
                fetch(data.url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = data.file_name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },
        }
    }
</script>
